/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */

import {
  elemAnimate,
  elemAppend,
  elemFromObject,
  elemHeight,
  elemRemove,
  elemStyle,
  elemWidth,
} from 'utilities/elem.js';

window._wq.push((Wistia) => {
  if (Wistia.DataApi.ProgressBar) {
    return;
  }

  return (Wistia.DataApi.ProgressBar = class ProgressBar {
    constructor(elem) {
      this.containerElem = elem;
      this.originalChildNodes = elem.childNodes.filter((child) => child.nodeType === 1);
      this._ratio = 0;
    }

    ratio(ratio) {
      this._ratio = ratio;
      return this.updateBar();
    }

    barWidth() {
      return Math.round(elemWidth(this.containerElem) * this._ratio);
    }

    updateBar() {
      return elemAnimate(this.barElem, {
        width: `${this.barWidth()}px`,
        time: 200,
        easing: 'linear',
      });
    }

    instaUpdateBar() {
      return elemStyle(this.barElem, { width: `${this.barWidth()}px` });
    }

    showProgressBar() {
      this.hideOriginalContent();
      elemAppend(this.containerElem, this.createBarElem());
      return this.fit();
    }

    hideProgressBar() {
      return elemRemove(this.barElem);
    }

    showOriginalContent() {
      this.hideProgressBar();
      return elemAppend(this.containerElem, this.originalChildNodes);
    }

    hideOriginalContent() {
      return elemRemove(this.originalChildNodes);
    }

    createBarElem() {
      return this.barElem != null
        ? this.barElem
        : (this.barElem = elemFromObject({
            className: 'wistia_progress_bar',
            style: {
              backgroundColor: '#1e71e7',
              left: 0,
              position: 'absolute',
              top: 0,
            },
          }));
    }

    fit() {
      if (this.barElem) {
        const containerHeight = elemHeight(this.containerElem);
        elemStyle(this.barElem, { height: `${containerHeight}px` });
        return this.instaUpdateBar();
      }
    }
  });
});
