/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
import * as objectUtilities from 'utilities/obj.js';

export const mediaResponseTransformer = (mediasApiResponseJson) => {
  moveIncludedObjectsUpToTheDataLevel(mediasApiResponseJson);
  return moveAttributesUpToTheDataLevel(mediasApiResponseJson);
};

const moveIncludedObjectsUpToTheDataLevel = function (json) {
  const thumbnail = findIncludedAttribute(json, 'thumbnail');
  const mediaGroup = findIncludedAttribute(json, 'media_group');
  const storyboard = findIncludedAttribute(json, 'storyboard');
  if (thumbnail) {
    json.data.thumbnail = thumbnail.attributes;
  }
  if (mediaGroup) {
    json.data.media_group = objectUtilities.merge(mediaGroup.attributes, { id: mediaGroup.id });
  }
  if (storyboard) {
    return (json.data.storyboard = objectUtilities.merge(storyboard.attributes, {
      id: storyboard.id,
    }));
  }
};

const moveAttributesUpToTheDataLevel = function (responseJson) {
  const { attributes } = responseJson.data;
  return Object.keys(attributes).map(
    (attribute) => (responseJson.data[attribute] = attributes[attribute]),
  );
};

const findIncludedAttribute = function (json, attribute) {
  const included = objectUtilities.filter(json.included, (incl) => incl.type === attribute);
  if (included.length > 0) {
    return included[0];
  }
  return null;
};
