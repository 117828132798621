/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
import * as bindify from 'utilities/bindify.js';

class UploadButton {
  constructor(buttonElem, options = {}) {
    this.bind = this.bind.bind(this);
    this.trigger = this.trigger.bind(this);
    this.unbind = this.unbind.bind(this);
    this.showUploading = this.showUploading.bind(this);
    this.showUploadVideo = this.showUploadVideo.bind(this);
    this.showFailed = this.showFailed.bind(this);
    this.flashCancelled = this.flashCancelled.bind(this);
    this.flashSuccess = this.flashSuccess.bind(this);
    this.options = options;
    this.buttonElem = buttonElem;
    this.wrapperElem = this.buttonElem;

    // This should be called before any button state change (e.g. uploading to
    // failed, failed to upload_video, etc.). Then each state should redefine
    // it so it returns the button to a neutral state before the next state
    // change.
    this._cleanUpState = function () {};

    // Set inital state, different from setState which is an update that can
    // be overridden by subclasses
    this._lastState = 'upload_video';
    this._state = 'upload_video';
  }

  bind() {
    return bindify.bind.apply(this, arguments);
  }

  trigger() {
    return bindify.trigger.apply(this, arguments);
  }

  unbind() {
    return bindify.unbind.apply(this, arguments);
  }

  setState(state) {
    this._lastState = this._state;
    this._state = state;
    return this.trigger('statechange', state, this._lastState);
  }

  showUploading(ratio) {
    return this.setState('uploading');
  }

  showUploadVideo() {
    return this.setState('upload_video');
  }

  showFailed(reason) {
    return this.setState('upload_failure');
  }

  flashCancelled() {
    this.setState('upload_cancelled');
    return this.setState('upload_video');
  }

  flashSuccess() {
    this.setState('upload_success');
    return this.setState('upload_video');
  }

  updateUploadingPercent(ratio) {}

  noUploadSupport(bool) {}
}

export { UploadButton };
