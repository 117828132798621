export function pollRetry(conditionFn, successCb, errorCb, pollMs, timeoutMs, isTimeout = null) {
  let timeout = isTimeout;
  if (timeout === null) {
    timeout = false;
    setTimeout(() => {
      timeout = true;
    }, timeoutMs);
  }
  const result = conditionFn();

  if (result) {
    successCb(result);
  } else if (timeout) {
    errorCb(new Error(`Timed out after ${timeoutMs} ms`));
  } else {
    setTimeout(
      () => pollRetry(conditionFn, successCb, errorCb, pollMs, timeoutMs, timeout),
      pollMs,
    );
  }
}
