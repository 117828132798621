/*
 * decaffeinate suggestions:
 * DS002: Fix invalid constructor
 * DS102: Remove unnecessary code created because of implicit returns
 * DS202: Simplify dynamic range loops
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
import { StopGo } from 'utilities/stopgo.js';
import {
  elemAddClass,
  elemAppend,
  elemFromObject,
  elemPrepend,
  elemRebind,
  elemRemove,
  elemRemoveClass,
  elemStyle,
} from 'utilities/elem.js';
import { doTimeout } from 'utilities/timeout-utils.js';
import { seqId } from 'utilities/seqid.js';
import { dynamicImport } from 'utilities/dynamicImport.ts';
import { UploadButton } from './upload_button.js';

window._wq.push((Wistia) => {
  if (Wistia.DataApi.DefaultUploadButton) {
    return;
  }

  return (Wistia.DataApi.DefaultUploadButton = class DefaultUploadButton extends UploadButton {
    constructor(buttonElem, options = {}) {
      super(...arguments);
      this.showUploadVideo = this.showUploadVideo.bind(this);
      this.onNoUploadSupportClick = this.onNoUploadSupportClick.bind(this);
      this.showUploading = this.showUploading.bind(this);
      this.onUploadingOverlayClick = this.onUploadingOverlayClick.bind(this);
      this.onUploadingOverlayMouseEnter = this.onUploadingOverlayMouseEnter.bind(this);
      this.onUploadingOverlayMouseLeave = this.onUploadingOverlayMouseLeave.bind(this);
      this.showFailed = this.showFailed.bind(this);
      this.onFailureOverlayClick = this.onFailureOverlayClick.bind(this);
      this.showCancelled = this.showCancelled.bind(this);
      this.flashCancelled = this.flashCancelled.bind(this);
      this.showSuccess = this.showSuccess.bind(this);
      this.flashSuccess = this.flashSuccess.bind(this);
      this.options = options;
      this.classPrefix = this.options.classPrefix || 'wistia_upload';
      this.wrapperElem = elemFromObject({
        class: `${this.classPrefix}_button`,
        style: {
          position: 'relative',
        },
      });

      this.buttonElem.innerHTML = '';
      this.uuid = seqId('wistia_upload_button_');

      this._noCancelPrompt = new StopGo();
      this._noCancelPrompt(true);

      dynamicImport('assets/external/interFontFace.js');

      elemAppend(this.buttonElem, this.wrapperElem);
      elemAppend(this.wrapperElem, this.createTextElem());

      this.showUploadVideo();
    }

    createTextElem() {
      return this.textElem != null
        ? this.textElem
        : (this.textElem = elemFromObject({
            innerHTML: this.defaultText(),
            class: `${this.classPrefix}_button_text`,
          }));
    }

    createOverlayElem() {
      return this.overlayElem != null
        ? this.overlayElem
        : (this.overlayElem = elemFromObject({
            class: `${this.classPrefix}_button_overlay`,
            style: {
              height: '100%',
              left: 0,
              position: 'absolute',
              top: 0,
              width: '100%',
            },
          }));
    }

    createUploadBarElem() {
      return this.uploadBarElem != null
        ? this.uploadBarElem
        : (this.uploadBarElem = elemFromObject({
            class: `${this.classPrefix}_bar`,
          }));
    }

    defaultText() {
      return this.options.defaultText || 'Upload Video';
    }

    resetText() {
      return (this.textElem.innerHTML = this.defaultText());
    }

    showUploadVideo() {
      this._cleanUpState();
      this.setState('upload_video');
      this.resetText();
      if (this.noUploadSupport()) {
        if (!this.overlayElem) {
          elemAppend(this.wrapperElem, this.createOverlayElem());
          elemRebind(this.overlayElem, 'click', this.onNoUploadSupportClick);
        }
      }
      return (this._cleanUpState = () => {
        this.removeOverlayElem();
        this.resetText();
        return this.clearClasses();
      });
    }

    noUploadSupport(bool) {
      if (bool != null) {
        return (this._noUploadSupport = bool);
      }
      if (this._noUploadSupport != null) {
        return this._noUploadSupport;
      }
      return false;
    }

    onNoUploadSupportClick(event) {
      event.preventDefault();
      // eslint-disable-next-line no-alert
      alert(
        'Uploading is not supported with this version of your browser. Please upgrade to a modern version.',
      );
      return true;
    }

    showUploading(ratio) {
      if (this._state !== 'uploading') {
        this._cleanUpState();
      }
      this.setState('uploading');

      if (!this.uploadingElem) {
        elemPrepend(this.wrapperElem, this.createUploadBarElem());
      }

      this.updateUploadingPercent(ratio);

      if (!this.overlayElem) {
        elemAppend(this.wrapperElem, this.createOverlayElem());
        elemRebind(this.overlayElem, 'click', this.onUploadingOverlayClick);
        if (!Wistia.detect.ipad && !Wistia.detect.iphone && !Wistia.detect.android) {
          elemRebind(this.overlayElem, 'mouseenter', this.onUploadingOverlayMouseEnter);
          elemRebind(this.overlayElem, 'mouseleave', this.onUploadingOverlayMouseLeave);
        }
      }

      return (this._cleanUpState = () => {
        Wistia.eventLoop?.remove(`${this.uuid}.ellipsis_animation`);
        this._noCancelPrompt(true);
        this.resetText();
        this.clearClasses();
        this.removeUploadBarElem();
        return this.removeOverlayElem();
      });
    }

    onUploadingOverlayClick(event) {
      event.preventDefault();
      event.stopPropagation();

      // eslint-disable-next-line no-alert
      if (confirm('Cancel uploading?')) {
        return this.trigger('confirmed-cancel');
      }
    }

    onUploadingOverlayMouseEnter() {
      const previousText = this.textElem.innerHTML;
      this._noCancelPrompt(false);
      this.textElem.innerHTML = 'Cancel?';
      return this._noCancelPrompt(() => {
        return (this.textElem.innerHTML = previousText);
      });
    }

    onUploadingOverlayMouseLeave() {
      return this._noCancelPrompt(true);
    }

    showFailed(reason) {
      this._cleanUpState();
      this._failureReason =
        reason ||
        'An error occurred while uploading the file. Please check your network connection and try again.';

      this.setState('upload_failure');
      this.textElem.innerHTML = 'Upload failed';
      this.wrapperElem.setAttribute('title', 'Upload failed. Click for details and to try again.');

      elemAppend(this.wrapperElem, this.createOverlayElem());
      elemRebind(this.overlayElem, 'click', this.onFailureOverlayClick);

      return (this._cleanUpState = () => {
        this.clearClasses();
        this.removeOverlayElem();
        this.resetText();
        return (this._failureReason = null);
      });
    }

    onFailureOverlayClick(event) {
      event.preventDefault();
      event.stopPropagation();
      // eslint-disable-next-line no-alert
      alert(this._failureReason);
      this.showUploadVideo();
      return this.removeOverlayElem();
    }

    showCancelled() {
      this._cleanUpState();
      this.setState('upload_cancelled');
      this.textElem.innerHTML = 'Upload cancelled';
      return (this._cleanUpState = () => {
        this.clearClasses();
        return this.resetText();
      });
    }

    removeOverlayElem() {
      if (this.overlayElem) {
        elemRemove(this.overlayElem);
        return (this.overlayElem = null);
      }
    }

    removeUploadBarElem() {
      if (this.uploadBarElem) {
        elemRemove(this.uploadBarElem);
        return (this.uploadBarElem = null);
      }
    }

    flashCancelled() {
      this.showCancelled();
      return doTimeout(`${this.uuid}.flash.show_upload_video`, this.showUploadVideo, 1600);
    }

    showSuccess() {
      this._cleanUpState();
      this.setState('upload_success');
      this.textElem.innerHTML = 'Success!';
      return (this._cleanUpState = () => {
        this.clearClasses();
        return this.resetText();
      });
    }

    flashSuccess() {
      this.showSuccess();
      return doTimeout(`${this.uuid}.flash.show_upload_video`, this.showUploadVideo, 1600);
    }

    uploadPercent(ratio = this._uploadRatio) {
      return `${Math.round(100 * (ratio || 0))}%`;
    }

    setState(state) {
      const lastState = this._state;
      this._state = state;
      this.clearClasses();
      elemAddClass(this.wrapperElem, `wistia_${state}`);
      return this.trigger('statechange', state, lastState);
    }

    clearClasses() {
      elemRemoveClass(this.wrapperElem, 'wistia_uploading');
      elemRemoveClass(this.wrapperElem, `${this.classPrefix}_success`);
      elemRemoveClass(this.wrapperElem, `${this.classPrefix}_failure`);
      return elemRemoveClass(this.wrapperElem, `${this.classPrefix}_cancelled`);
    }

    updateUploadingPercent(ratio) {
      this._uploadRatio = ratio;
      return this._noCancelPrompt(() => {
        if (ratio === '') {
          this.textElem.innerHTML = 'Uploading';
          const ellipses = new Array(3);
          ellipses[0] = elemFromObject({ tagName: 'span', innerHTML: '&nbsp;.' });
          ellipses[1] = elemFromObject({ tagName: 'span', innerHTML: '&nbsp;.' });
          ellipses[2] = elemFromObject({ tagName: 'span', innerHTML: '&nbsp;.' });
          for (let ellipsis of ellipses) {
            elemStyle(ellipsis, { transition: 'opacity 200ms ease' });
          }
          elemAppend(this.textElem, ellipses);
          let loops = 0;
          Wistia.eventLoop.add(`${this.uuid}.ellipsis_animation`, 200, () => {
            const endIndex = loops % ellipses.length;
            const inverted = loops % (ellipses.length * 2) >= ellipses.length;
            for (
              let i = 0, end = ellipses.length, asc = end >= 0;
              asc ? i < end : i > end;
              asc ? (i += 1) : (i -= 1)
            ) {
              if (inverted) {
                if (i <= endIndex) {
                  elemStyle(ellipses[i], { opacity: 0 });
                } else {
                  elemStyle(ellipses[i], { opacity: 1 });
                }
              } else if (i <= endIndex) {
                elemStyle(ellipses[i], { opacity: 1 });
              } else {
                elemStyle(ellipses[i], { opacity: 0 });
              }
            }
            return (loops += 1);
          });
        } else {
          this.textElem.innerHTML = `Uploading... ${this.uploadPercent(ratio)}`;
        }
        if (this.uploadBarElem) {
          const fullWidthWithPadding = this.containerWidth() + 2;
          const roundedWidth = Math.round(ratio * fullWidthWithPadding);
          return elemStyle(this.uploadBarElem, { width: `${roundedWidth}px` });
        }
      });
    }

    containerWidth() {
      return 120;
    }

    containerHeight() {
      return 34;
    }

    fit() {
      return elemStyle(this.wrapperElem, {
        width: `${this.containerWidth()}px`,
        height: `${this.containerHeight()}px`,
      });
    }
  });
});
