/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
window._wq.push((Wistia) => {
  if (Wistia.DataApi.Media) {
    return;
  }

  return (Wistia.DataApi.Media = class Media {
    constructor(dataApi) {
      this.dataApi = dataApi;
    }

    find(hashedId, params, options) {
      return this.dataApi.get(`/v2/medias/${hashedId}`, params, options);
    }

    // eslint-disable-next-line default-param-last
    embedCode(hashedId, params = {}, callback) {
      const url = new window.URL(
        `${window.location.protocol}//${Wistia.remote.embedHost()}/oembed.json`,
      );
      for (const [key, value] of Object.entries(params)) {
        url.searchParams.append(key, value);
      }
      url.searchParams.append(
        'url',
        `https://${Wistia.remote.externalEmbedHost()}/embed/medias/${hashedId}`,
      );

      return fetch(url)
        .then((resp) => resp.json())
        .then((data) => {
          if (callback) {
            callback(data.html, data);
          }
        });
    }
  });
});
