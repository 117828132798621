// The way the uploader is used it needs to remain a Common JS module
// it is embedded like:
// <script src="//fast.wistia.io/assets/external/api.js" async></script>
// and without the type="module" attribute, it would not work in the browser

require('../embeds/uploader/_define_wq.js');

require('../embeds/uploader/_core.js');
require('../embeds/uploader/_media_class.js');
require('../embeds/uploader/_progress_bar.js');
require('../embeds/uploader/_upload_button.js');
require('../embeds/uploader/_uploader.js');
require('../embeds/uploader/_init.js');
const { pollRetry } = require('../utilities/retry.js');

module.exports = {
  getUploader: (timeoutMs = 60000, pollMs = 0) =>
    new Promise((resolve, reject) => {
      pollRetry(() => window.Wistia && window.Wistia.Uploader, resolve, reject, pollMs, timeoutMs);
    }),
};
