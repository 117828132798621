/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
import { UploadButton } from './upload_button.js';
import './default_upload_button.js';

window._wq.push((Wistia) => {
  if (Wistia.DataApi.UploadButton) {
    return;
  }
  return (Wistia.DataApi.UploadButton = UploadButton);
});
